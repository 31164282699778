<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 入口
-->
<template>
  <div id="app" >
    <div class="body" :style="{'min-height':myHeight}" id="anchor_0">
      <my-head @changePage="changePage"/>
      <router-view :key="key" />
    </div>
  </div>
</template>

<script>
import myHead from '@/components/myHead/index.vue'
import myFoot from '@/components/myFoot/index.vue'

export default {
  name: 'App',
  data() {
    return {
      myHeight: '',
    }
  },
  components: {
    myHead: myHead,
    myFoot: myFoot
  },
  created() {
    this.pageState()
    this.getHeight()
  },
  computed: {
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + +new Date()
        : this.$route + +new Date()
    },
  },
  // mounted() {
  //   document.getElementById('page_loading').style.display = 'none'
  // },
  methods: {

    changePage(i) {
      this.$store.commit('changePage', i)
    },
    getHeight() {
      let height = document.body.offsetHeight - 286
      this.myHeight = height + 'px'
    },
    pageState() {
      //在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem('store'))
          )
        )
        this.$i18n.locale = this.$store.state.language
      }

      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      })
    },
  },
}
</script>

<style lang="scss">
.body {
  min-height: 100%;
}
</style>
