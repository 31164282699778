<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 头部组件
-->
<template>
  <div class="nav-box">
    <!-- 白底nav -->
    <div class="nav_bottom_out" :class="navStatus?'':'nav_bottom_out_white'">
      <div :class="navStatus?'nav_bottom w1200':'nav-white nav_bottom w1200'">
        <div class="left logoNav">
          <div class="navDiv">
            <router-link to="index" class="nav" @click.native="changePage(0)">
              <img v-if="navStatus" :src="require('@/assets/img/index/logo.png')" class="left hand" alt="匯達(香港)保險經紀" title="匯達(香港)保險經紀"/>
              <img v-else :src="require('@/assets/img/index/nav_logo2.png')" class="left hand" alt="匯達(香港)保險經紀" title="匯達(香港)保險經紀"/>
            </router-link>
          </div>
        </div>
        <div class="right navDiv-wrap">
          <div class="navDiv" :class="[$store.state.page==0?'nav nav_active':'nav']" @click="jumpAnchor('/index', 0)">
            {{ '首页' | sLang }}
          </div>
          <div class="navDiv" :class="[$store.state.page==1?'nav  nav_active':'nav']" @click="jumpAnchor('/service', 1)">
            {{ '客户服务' | sLang }}
          </div>
          <div class="navDiv" :class="[$store.state.page==2?'nav  nav_active':'nav']" @click="jumpAnchor('/channel',  2)">
            {{ '汇达频道' | sLang }}
          </div>
          <div class="navDiv" :class="[$store.state.page==3?'nav  nav_active':'nav']" @click="jumpAnchor('/aboutUs', 3)">
            {{ '关于我们' | sLang }}
          </div>
          <div class="navDiv" @click="changeLang">
            {{ language | sLang }}
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myHead',
  data() {
    return {
      language: '切换简体', // 0简体，1繁体
      navStatus: null, //导航栏显示样式区分，透明为true，白色为false
      pageList: {
        '/index': 0,
        '/service': 1,
        '/channel': 2,
        '/article' : 2,
        '/aboutUs': 3
      }
    }
  },
  watch: {
    '$route.path': function(val) {
      if (this.$route.path === '/index') {
        this.navStatus = true
        window.addEventListener('scroll', this.scrolling)
      } else {
        this.navStatus = false
      }
      this.$emit('changePage', this.pageList[val])
    },
    navStatus:function(val){
      console.log('222222',val)
    }
  },
  mounted() {
    console.log("---mounted---")
    if (window.location.href.indexOf('/index') > -1) {
      this.$nextTick(() => {
        window.addEventListener('scroll', this.scrolling)
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
        // 判断是否到了最顶部
        if (scrollTop > 200) {
          this.navStatus = false
        } else {
          this.navStatus = true
        }
      })
    } else {
      window.removeEventListener("scroll", this.scrolling);
    }
  },
  methods: {
    // 监听滚动条，首页显示nav导航栏样式变化
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // 首页滚动条往上200+非首页都是白底导航
      if (scrollTop > 200) {
        this.navStatus = false
      } else {
        this.navStatus = true
      }
      console.log('this.navStatus', this.navStatus)
    },
    changeLang() {
      let i = ''
      if (this.$store.state.language === 'zh') {
        i = 'tc'
        this.language = '切换简体'
        document.title = '匯達(香港)保險經紀有限公司'
      } else {
        i = 'zh'
        this.language = '切换繁体'
        document.title = '汇达(香港)保险经纪有限公司'
      }
      this.$i18n.locale = i
      this.$store.commit('changeLanguage', i)
      // 如果是联系我们页面，修改地图简体繁体
      // if( this.$route.path == '/contact-us' ){
      //   this.$mapjs();
      // }
    },
    changePage(i) {
      this.$emit('changePage', i)
    },
    // path--要去的页面路由
    // i--二级导航的索引
    jumpAnchor(path, i) {
      // 要去的页面导航的索引

      // 如果当前是首页
      if (path === '/index') {
        // 首页
        let anchor = document.getElementById('anchor_' + i)
        if ( !!anchor ) {
          let go = anchor.offsetTop
          document.documentElement.scrollTop = go
          document.body.scrollTop = go
        }
        this.navStatus = true
        window.addEventListener("scroll", this.scrolling);
      } else {
        this.navStatus = false;
        window.removeEventListener("scroll", this.scrolling);
      }

      this.$router.push(path);
      this.$emit('changePage', i)
      // else{
      //   //其他页面（关于我们，投资策略），触发菜单点击事件
      //   document.querySelectorAll('.menu span')[i].click();
      // }
		}
  },
}
</script>

<style lang="scss">
.nav-box {
  position: fixed;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  .nav_top_out {
    width: 100%;
    // background: #0b121c;
    .nav_top {
      height: 0.34rem;
      line-height: 0.34rem;
      color: #f2f3f5;
      overflow: hidden;
      padding: 0 0.20rem;
      .language {
        margin-left: 0.30rem;
        // width: 0.80rem;
        text-align: center;
        transform:translateY(-50%) scale(0.83, 0.83);
        position: relative;
        top: 50%;
        span {
          float: left;
          // width: 50%;
          width: 0.40rem;
          box-sizing: content-box;
          height: 0.22rem;
          line-height: 0.21rem;
        }
        .language_l {
          // padding: 0.03rem 0.08rem 0.03rem 0.12rem;
          border: 0.01rem solid #fff;
          border-radius: 1.00rem 0 0 1.00rem;
        }
        .language_r {
          // padding: 0.03rem 0.12rem 0.03rem 0.08rem;
          border: 0.01rem solid #fff;
          border-radius: 0 1.00rem 1.00rem 0;
        }
        .language_active {
          background: #fff;
          color: #0b121c;
        }
      }
    }
  }
  .nav_bottom_out_white{
    background: #ffffff;
  }
  .nav_bottom_out {
    // 透明底
    .nav_bottom {
      height: 0.80rem;
      line-height: 0.70rem;
      overflow: hidden;
      transition: height 0.3s,;
      img {
        width: 0.90rem;
        // height: 0.40rem;
        transition: 0.4s;
        margin-top: 0.3rem
      }
      div{
        height: 100%;;
      }
      // .logoNav{
      //   width: 10%;
      //   .navDiv{
      //     float: right;
      //     padding-top:0.40rem;
      //   }
      // }
      .nav{
        display: block;
        height: 0.32rem;
      }
      .navDiv{
        width: 1rem;
        font-size: 0.14rem;
				color: #ffffff;
				margin-left: 0.26rem;
        height: 100%;
        padding: 0 0 0.05rem;
				cursor: pointer;
				display: inline-block;
				position: relative;
				line-height: 0.8rem;
        text-align: center;
        transition: height 0.3s;
        transform: scale(0.8);
        transform-origin: left;
        transition: 0.3s;
        transform: scale(0.8);
        transform-origin: left;
				&:first-child{
					padding-bottom: 0;
					margin-left: 0;
				}
      }
      .navDiv:hover {
        font-weight: bold;

				.secondNav{
					display: block;
				}
      }
      .nav_active {
        font-weight: bold;
        // border-bottom: 0.02rem #dab678 solid;
        &:after{
          content: '';
          width: 0.16rem;
          height: 0.03rem;
          background: #ffffff;
          position: absolute;
          left: 50%;
          bottom: 0.1rem;
          margin-left: -0.08rem;
          transition: bottom 0.3s;
        }
      }
      .navDiv-wrap{
        line-height: 0;
      }
    .secondNav{
      display: none;
      position: absolute;
      width: 2.48rem;
      height: 1.42rem;
      padding-top: 0.06rem;
      padding-bottom: 0.06rem;
      background: #fff;
      box-shadow: 0.00rem 0.04rem 0.08rem 0.00rem rgba(0, 0, 0, 0.26);
      border-radius: 0.04rem;
      top: 0.45rem;
      left: -1.84rem;
      z-index: 1;

  }
    }
    // 白色底
    .nav-white{
      height: 0.55rem;
      background-color: #fff;
      line-height: 0.55rem;
      transition: height 0.3s, background-color 0.5s;
      .logoNav{
        .navDiv{
          padding-top:0.17rem !important;
          transition: height 0.3s;
        }
        .hand{
          width: 0.68rem;
          transition: 0.3s;
          margin-top: -0.02rem;
        }
      }
      .navDiv-wrap{
        line-height: 0;
        .navDiv{
          width: 1rem;
          font-size: 0.14rem;
          // font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          padding:0;
          transform: scale(0.8);
          transform-origin: left;
          color:#333333;
          transition: 0.04s;
          line-height: 0.5rem;
          transform: scale(0.8);
          transform-origin: left;
          &.nav_active:after {
              background: #0066CC;
              bottom: 0.1rem;
          }
        }
      }
    }
  }
  
  
}
</style>